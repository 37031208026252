import { Router } from "./domains/app/router";
import { v4 as uuidv4 } from 'uuid';

require('../scss/main.scss');

document.addEventListener("DOMContentLoaded", async (event) => {
    const router = new Router(document.location.href);
    const localStorageAvailable = lsTest();

    if (localStorageAvailable) {
        const visitorId = getVisitorId();

        router.on('index', async () => {
            const pageId = '44154810-3f29-444f-afeb-55fc27a54832';
            await recordHit(pageId, visitorId);
            await updatePageStats(pageId, visitorId);
        });

        // Gallery listing
        router.on('galleries', async () => {
            const pageId = 'bb759c4c-9ef7-4d13-ad6e-3a5916c41c17';
            await recordHit(pageId, visitorId);
            await updatePageStats(pageId, visitorId);
        });

        // Individual gallery
        router.on('gallery', async () => {
            const pageId = '0e4828f1-ea0a-4c80-a23a-072ada460999';
            await recordHit(pageId, visitorId);
            await updatePageStats(pageId, visitorId);
        });

        router.on('contact', async () => {
            const pageId = '6de9685f-5c61-470a-93df-cc1aa442e212';
            await recordHit(pageId, visitorId);
            await updatePageStats(pageId, visitorId);
        });
    }
});

async function recordHit(pageId: string, visitorId: string) {
    const win: any = window;
    const result = await win.acNetApi.pageService.recordHit(visitorId, pageId);
    if (!result) {
        console.log('Failed to record PageHit');
    }
}

async function updatePageStats(pageId: string, visitorId: string) {
    const win: any = window;
    const pageStats = await win.acNetApi.pageService.getPageStats(pageId);
    if (!pageStats) {
        console.log('Failed to get page stats');
        return;
    }

    document.getElementById('pageHits').innerHTML = pageStats.num_hits;

    const visitorStats = await win.acNetApi.visitorService.getVisitorStats(visitorId);
    if (!visitorStats) {
        console.log('Failed to get visitor stats');
        return;
    }


    document.getElementById('visitorHits').innerHTML = visitorStats.num_hits;

    console.log(pageStats, visitorStats);
}

function getVisitorId(): string {
    let visitorId = localStorage.getItem('visitorId');
    if ((visitorId === null) || (visitorId.length === 0)) {
        visitorId = uuidv4();
        localStorage.setItem('visitorId', visitorId);
    }

    console.log(visitorId);

    return visitorId;
}

function lsTest(){
    let test = 'test';

    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}
