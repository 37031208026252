export class Router {
    constructor(private url: string) {

    }

    public on(matchUri: string, callback: CallableFunction): void {
        if (this.getUri() === matchUri) {
            callback();
        }
    }

    private getUri(): string {
        const urlParts = this.url.split('/');

        let lastPart = '';

        for (const part of urlParts) {
            if (part.indexOf('.html') >= 0) {
                const pageName = part.substr(0, part.length - 5);

                // If the pageName is a UUID, we want to pass back the parent element in the url,
                // e.g. /gallery/<uuid>.html
                // we want 'gallery'
                if (this.isUuid(pageName)) {
                    return lastPart;
                }

                return pageName;
            }

            lastPart = part;
        }

        return 'index';
    }

    private isUuid(possibleUuid: string): boolean {
        const parts = possibleUuid.split('-');
        return parts.length === 5;
    }
}
